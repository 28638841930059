import { Button, Divider, Grid } from "@mui/material";
import SocialButton from "./SocialButton";
import imgMobi from './images/person-nfe-mobi-maintance.png'
import img from './images/person-nfe-maintance.png'


export default function Maintance(){
    const openPoliticasPrivacidade = () => {
        window.open('https://www.sebrae.com.br/Sebrae/Portal%20Sebrae/LGPD/Sebrae%20-%20Pol%C3%ADtica%20de%20Privacidade.pdf');
    }

    const openLGPD = () => {
        window.open('https://www.sebrae.com.br/sites/PortalSebrae/canais_adicionais/conheca_lgpd');
    }

    const atatchFontAwsome = () => {
        const script = document.createElement("script");
        script.src = "https://kit.fontawesome.com/571bdc6fcc.js";
        script.async = true;
        script.crossOrigin = "anonymous"
    
        document.body.appendChild(script);
    }

      
    atatchFontAwsome();

    return (
        <div>
            <div className='centerContainer'>
            <div className='container'>
                <div className='content'>
                    <div className="flexContainer">
                        <div className='logoImageContainer'>
                            <img alt='logo' className='logoImage' src='https://image.cliente.sebrae.com.br/lib/fe2f11727364047c771d77/m/2/aabdcdca-81ff-429b-9023-ea9444693f30.png' />
                        </div>
                        <div className="flexTextRow">
                            <div className="flexTextColumn">
                                <div className='flexTitle'>
                                    Aviso importante
                                </div>
                                <div className='flexInfo'>
                                    <p>
                                        O nosso sistema emissor de NF-e está passando por uma atualização, o que está gerando instabilidade nas emissões e ocasionalmente, suspensões no serviço. 
                                    </p>
                                    <p>
                                        Estamos empenhados trabalhando para que o serviço permaneça estável com a maior brevidade possível.
                                    </p>
                                    <p className="strongMensage">
                                        Em caso de urgências na emissão, utilizar o antigo emissor do Sebrae.
                                    </p>
                                </div>
                            </div>
                            <div className="flexTextRow">
                                <div className='personImageContainer'>
                                    <img alt='Personagem Contador' className='flexPersonMobiImage' src={imgMobi} />
                                    <img alt='Personagem Contador' className='flexPersonImage' src={img} />
                                </div>
                            </div>
                        </div>
                        <div className="flexThankyou">
                            Obrigado pela sua compreensão.
                        </div>
                    </div>
                    <div className='firstFooter'>
                        <p>
                        <div style={{marginBottom: '10px'}}>
                            &nbsp;
                        </div>
                        Em observância à Lei nº 13.709/18 – Lei Geral de Proteção de Dados Pessoais, o Sebrae disponibiliza para a consulta de seus clientes os Termos de Uso do Portal e Política de Privacidade. Para ter acesso a integra dos documentos clique abaixo:
                        </p>
                        <div style={{
                        marginTop:'10px'
                        }}>
                        <Button 
                            onClick={openLGPD}
                            variant='contained'
                            sx={{
                            color: '#FFFFFF',
                            backgroundColor: '#3b4aff',
                            height: '45px',
                            paddingLeft:'20px',
                            paddingRight:'20px',
                            fontSize: '16px',
                            borderRadius: '5px',
                            textTransform: 'none'
                            }}
                            style={{
                            marginRight: '10px'
                            }}
                        >Conheça a LGPD</Button>
                        <Button 
                            onClick={openPoliticasPrivacidade}
                            variant='contained'
                            sx={{
                            color: '#FFFFFF',
                            backgroundColor: '#3b4aff',
                            height: '45px',
                            paddingLeft:'20px',
                            paddingRight:'20px',
                            fontSize: '16px',
                            borderRadius: '5px',
                            textTransform: 'none'
                            }}  
                        >Políticas de Privacidade</Button>
                        </div>
                    </div>

                    <div className='footer'>
                        <p >
                            <div className='spacing'>
                                &nbsp;
                            </div>
                            <div>
                                <Grid container spacing={3}>
                                <Grid item xs className='grid'>
                                    <span className='ajustaLinha'><a href='https://www.sebrae.com.br/sites/PortalSebrae' rel="noreferrer" target="_blank">sebrae.com.br</a></span> 
                                </Grid>
                                <Divider orientation="vertical" variant="middle" style={{borderColor: '#FFFFFF'}}  flexItem />
                                <Grid item xs className='grid'>
                                    <span>Central de Relacionamento: <br/>0800 570 0800</span>
                                </Grid>
                                <Divider orientation="vertical" variant="middle" style={{borderColor: '#FFFFFF'}} flexItem />
                                <Grid item xs className='grid'>
                                    <span className='ajustaLinha'>Fale Conosco</span>
                                </Grid>
                                </Grid>
                            </div>
                        </p>
                        <div style={{
                        marginTop:'10px'
                        }}>
                            <SocialButton className='socialIcons' ariaLabel='Facebook' destinyUrl='https://www.facebook.com/sebrae' iconCode='fa-facebook-f' />
                            <SocialButton className='socialIcons' ariaLabel='Instagram' destinyUrl='https://www.instagram.com/sebrae/' iconCode='fa-instagram' />
                            <SocialButton className='socialIcons' ariaLabel='Linkedin' destinyUrl='https://www.linkedin.com/company/sebrae/mycompany/' iconCode='fa-linkedin' />
                            <SocialButton className='socialIcons' ariaLabel='Twitter' destinyUrl='https://twitter.com/sebrae' iconCode='fa-twitter' />
                            <SocialButton className='socialIcons' ariaLabel='Youtube' destinyUrl='https://www.youtube.com/channel/UCBqosmarVhVAKYXGVt1JvnA' iconCode='fa-youtube' />
                        </div>
                    </div>
                </div>
                <div>
                    <div className='backgroundSGV'>
                        <div style={{
                        height: "1597px",
                        width: "1240px",
                        backgroundImage: "url(/fundo_maintance.png)",
                        backgroundSize: 'contain'
                        }} >&nbsp;</div>
                    </div>
                    </div>
                    <div>
                        <div className='backgroundSGVMobile'>
                            &nbsp;
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}