import './App.css';
import FormNFe from './FormNFe';
import Maintance from './Maintance';
import MigrateNFe from './MigrateNFe';

function App() {
  
  return (
    <div className="App">
      <Maintance />
    </div>
  );
}

export default App;
