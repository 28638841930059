import { IconButton } from "@mui/material";

export default function SocialButton({ariaLabel, className, style, iconCode, destinyUrl}:{ariaLabel?:string, className?:string, iconCode:string, destinyUrl:string, style?:React.CSSProperties}){
    const handleClick = () => {
        window.open(destinyUrl);
    }
    return (
        <IconButton style={style} className={className} aria-label={ariaLabel} onClick={handleClick}>
            <i className={"fa-brands "+iconCode}></i>
        </IconButton>
    )
}